<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          Pin Code
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="cancel"
        >
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          Enter pin to confirm
        </slot>
        &nbsp;<input v-model="pin" type="password" class="input-pin" placeholder="Pin Code">
      </section>

      <footer class="modal-footer">
        <slot name="footer">
        </slot>
        <button
          type="button"
          class="btn-green"
          @click="close"
        >
          OK
        </button>
        <button
          type="button"
          class="btn-green"
          @click="cancel"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PinModal',
    data:() => ({
      pin: '',
    }),
    methods: {
      close() {
        this.$emit('close', this.pin);
        this.pin = '';
      },
      cancel() {
        this.$emit('close', null);
        this.pin = '';
      }
    },
  };
</script>
<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }

  .modal {
    background: #000;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    /* flex-direction: column; */
    /* justify-content: flex-end; */
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    width: 100px;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
    margin: 0px 5px;
  }
  
  .input-pin {
    color: black;
    background: white;
  }
</style>
