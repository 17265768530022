<template>
  <v-dialog
      v-model="isEditVisible"
      scrollable
      persistent
      max-width="300px"
      dark
  >
    <v-card color="#1e1e1e">
      <v-card-title>Edit TX-Sensor Node</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 200px">
        <v-tabs v-model="tab" dark>
          <v-tabs-slider color="orange" dense></v-tabs-slider>
          <v-tab key="Sensors">Sensors</v-tab>
          <v-tab key="Function">Function</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" dark>
          <v-tab-item key="Sensors">
            <v-checkbox
                v-for="(sensor, i) in sensorList"
                :key="i"
                v-model="sensor.isSelected"
                :label="sensor.name"
                :value="sensor.isSelected"
                dense
            ></v-checkbox>
          </v-tab-item>
          <v-tab-item key="Function">
            <v-radio-group v-model="selectedOperation">
              <v-radio
                  v-for="(fn, i) in operations"
                  :key="i"
                  :label="fn"
                  :value="fn"
                  dense
              ></v-radio>
            </v-radio-group>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="green darken-1"
            text
            @click="save"
        >
          Save
        </v-btn>
        <v-btn
            color="red darken-1"
            text
            @click="close"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'SensorModal',
  props: {
    isEditVisible: Boolean,
    sensorList: Array,
    selectedOperation: String
  },
  data() {
    return {
      tab: null,
      operations: ['avg', 'min', 'max'],
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      let selectedSensor = [];
      this.sensorList.forEach((sensor) => {
        selectedSensor.push({
          // sensorDetails value here already changed by user
          name: sensor.name,
          isSelected: sensor.isSelected ?? false
        });
      });
      const result = {
        sensors: selectedSensor,
        operation: this.selectedOperation,
        ahu: this.sensorList[0].ahu
      };
      this.$emit('save', result);
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal {
  background: #000;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  /* flex-direction: column; */
  /* justify-content: flex-end; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  width: 100px;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
  margin: 0px 5px;
}

.input-pin {
  color: black;
  background: white;
}
</style>
