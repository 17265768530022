<template>
  <div id="controlPage" style="padding: 10px">
    <PinModal v-show="isModalVisible" @close="closeModal">
      <template v-slot:body>{{ modalMessage }}</template>
    </PinModal>
    <SensorModal :is-edit-visible="isEditVisible"
                 :sensor-list="sensorList"
                 :selected-operation="selectedOperation"
                 @close="cancelEditSensor"
                 @save="saveSensor"/>
    <table id="control" class="display" style="width: 99%; height: 80%;">
      <thead>
      <tr>
        <th>Phase</th>
        <th>AHU</th>
        <th>TX-Sensor Node</th>
        <th>Selector Switch</th>
        <th>Real-time Ambient °C</th>
        <th>Real-time RH %</th>
        <th>Supply Air Temp °C</th>
        <th>Return Air Temp °C</th>
        <th>PID Control Mode</th>
        <th>Supply Air SV °C</th>
        <!--<th>Valve Opening (MV) %</th>-->
        <th>AHU Valve Opening (MV) %</th>
        <th>Valve Opening Feedback %</th>
        <th>Set Automation Status</th>
        <!-- <th>Automation Device</th> -->
        <th>Ambient SV °C</th>
        <th>Supply Air SV Range °C</th>
        <th>Set Inverter Speed</th>
        <!-- <th rowspan="2">Phase</th> -->
        <!-- <th rowspan="2">AHU</th>
        <th rowspan="2">TX-Sensor Node</th>
        <th rowspan="2">Real-time Temperature °C</th>
        <th rowspan="2">Real-time Relative Humidity %</th> -->
        <!-- <th rowspan="2">Room Preasure (Pa)</th> -->
        <!-- <th rowspan="2">Room PM2.5 Reading (ug/m3)</th> -->
        <!-- <th rowspan="2">Supply Air Temp °C</th>
        <th rowspan="2">Return Air Temp °C</th>
        <th rowspan="2">Fan Speed (Hz)</th>
        <th rowspan="2">Fan kW % (rated power)</th>
        <th colspan="3" style="text-align:center;">Set Temperature (SV) °C</th> -->
        <!-- <th rowspan="2">Fault Code & Status</th> -->
      </tr>
      <!-- <tr>
        <th>°C</th>
        <th>ECO</th>
        <th>QR</th>
      </tr> -->
      </thead>
      <tbody>
      <tr>
        <td>Test Phase</td>
        <td>Test AHU2</td>
        <td>Test TX2</td>
        <td>Test Mode</td>
        <td>Test Realtime temp</td>
        <td>Test Realtime humid</td>
        <td>Test Rsuply</td>
        <td>Test Rreturn</td>
        <td>Test mode</td>
        <td>Test 22</td>
        <!--<td>Testmv</td>-->
        <td>Testvalve</td>
        <td>Testvalveauto</td>
        <td>Testvalveauto target</td>
        <td>Testvalveauto target</td>
        <td>Testvalveauto target</td>
        <td>Testvalveauto speed</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import PinModal from "../components/PinModal.vue";
import SensorModal from "../components/SensorModal";
import "jquery/dist/jquery.min.js";
import "jquery-ui/dist/jquery-ui.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "font-awesome/css/font-awesome.min.css"
import 'datatables.net-fixedheader-dt'
import $ from 'jquery';
import axios from 'axios';
import {API_SERVER} from "../config/index.js";

export default {
  data() {
    return {
      selectedAHU: '',
      sensorList: [],
      isEditSensor: false,
      isModalVisible: false,
      pending: null,
      modalMessage: "",
      changeDelay: 1000,
      isDelay: null,
      decreaseTempChange: 0,
      increaseTempChange: 0,
      initValue: 0,
      tempBefore: 0,
      decreaseMvChange: 0,
      increaseMvChange: 0,
      mvBefore: 0,
      decreaseInverterChange: 0,
      increaseInverterChange: 0,
      inverterBefore: 0,
      automationChange: {},
      automationBefore: {},
      automationRange: {},
      mainUrl: "",
      apiUrl: "",
      apiInverterUrl: "",
      isAutoLoad: false,
      isPauseAutoLoad: false,
      sensorDetails: {},
      isEditVisible: false,
      table: null,
      limitResult: [],
      selectedOperation: '',
    };
  },
  components: {
    PinModal,
    SensorModal
  },
  async beforeMount() {
    this.mainUrl = API_SERVER;
    // this.mainUrl = "http://localhost:3006";
    this.apiUrl = `${this.mainUrl}/data/control`;
    this.apiInverterUrl = `${this.mainUrl}/data/controlInverterSpeed`;
    await this.updateSensorDetails();
    axios.get(this.apiUrl + '/limit')
        .then((response) => {
          let result = response.data.data;
          this.limitResult = result;
        })
        .catch((e) => {
          console.log(e.message)
        })
  },
  methods: {
    async updateSensorDetails() {
      try {
        const response = await axios.get(this.apiUrl + '/sensors');
        this.sensorDetails = response.data.data;
      } catch (e) {
        console.log(e.message);
      }
    },
    async saveSensor(data) {
      this.isEditVisible = false;
      const pin = await this.getPin();
      if (pin) {
        // console.log(data)
        axios.post(this.apiUrl + '/saveSensorEdit', data, {
          headers: {
            "X-Pin": pin,
          },
        })
            .then(async () => {
              alert('Saved changes')
              // update newest data
              await this.updateSensorDetails()
              this.isPauseAutoLoad = false;
              this.isEditSensor = false;
              this.isEditVisible = false;
              let table = $("#control").DataTable();
              table.ajax.reload(null, false);
            })
            .catch((e) => {
              console.log(e)
            })
      }
    },
    cancelEditSensor() {
      this.isEditSensor = false;
      this.isEditVisible = false;
      this.isPauseAutoLoad = false;
    },
    getPin(message = "Enter pin code to confirm") {
      this.modalMessage = message;

      const promise = new Promise((resolve) => {
        this.pending = resolve;
        this.showModal();
      });
      return promise;
    },
    showModal() {
      this.isPauseAutoLoad = true;
      this.isModalVisible = true;
    },
    closeModal(pin) {
      this.isModalVisible = false;
      console.log("Modal closed", pin);
      if (this.pending) {
        this.pending(pin);
        this.pending = null;
      }
      if (!this.isEditSensor) this.isPauseAutoLoad = false;
      else if (!pin) this.isEditVisible = true;
    },
  },
  mounted: function () {
    const vm = this;
    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip();

      let page = 0;
      // let offset = 0;
      const inverterControl = {
        "nexperia-ph2c-ahu-1": true,
        "nexperia-ph2c-ahu-2": true,
        "nexperia-ph2c-ahu-3": true,
        "nexperia-ph2c-ahu-4": true,
        "nexperia-ph2c-ahu-5": true,
        "nexperia-ph2c-ahu-6": true,
        "nexperia-ph2c-ahu-be1": true,
        "nexperia-ph2c-ahu-fe1": true,
        "nexperia-ph2c-ahu-fe2": true,
        "nexperia-ph2c-ahu-me1": true,
      };

      function refreshTable() {
        let table = $("#control").DataTable();
        table.ajax.reload(null, false);
      }

      function saveLog(valueBefore, valueAfter, type, deviceId) {
        let data = {
          deviceId: deviceId,
          type: type,
          before: valueBefore,
          after: valueAfter,
          timestamp: parseInt(Date.now() / 1000),
        }
        axios.post(vm.apiUrl + '/saveControlLog', data)
            .then(() => {
              console.log(`${type} log saved`)
            })
            .catch((e) => {
              console.log(e)
            })
      }

      function setTemperature(deviceId, data, pin) {
        console.log("SET TEMP", data, pin);

        let tempValue = parseFloat(data.after).toFixed(1);

        //Modbus only accept int. Reading back should /10
        let newData = {
          value: tempValue * 10,
        };

        console.log("Controlling temperature", deviceId);
        $.ajax({
          url: `${vm.apiUrl}/${deviceId}/setCH1setPoint/${
              tempValue * 10.0
          }`,
          headers: {
            "X-Pin": pin,
          },
          // dataType: 'json',
          type: "post",
          // contentType: 'application/json',
          data: JSON.stringify(newData),
          // processData: false,
          success: function (response) {
            alert("Set Point Success!");
            saveLog(data.before, data.after, "temperature", deviceId)
            // refreshTable();
          },
          error: function (res) {
            alert("Set Point Failed! " + res.responseJSON.message);
            refreshTable();
          },
          complete: function () {
            vm.isPauseAutoLoad = false;
          }
        });
      }

      function setMv(deviceId, data, pin) {
        console.log("SET MV", data, pin);
        let mvValue = parseInt(data.after);

        console.log("Controlling MV", deviceId);
        $.ajax({
          url: `${vm.apiUrl}/${deviceId}/setCH1Mv/${mvValue * 10.0}`,
          headers: {
            "X-Pin": pin,
          },
          type: "post",
          success: function (response) {
            alert("Set MV Success!");
            saveLog(data.before, data.after, "mv", deviceId)
            // refreshTable();
          },
          error: function (res) {
            alert("Set MV Failed! " + res.responseJSON.message);
            refreshTable();
          },
          complete: function () {
            vm.isPauseAutoLoad = false;
          }
        });
      }

      function setInverterSpeed(deviceId, data, pin) {
        const table = $("#control").DataTable();
        let newData = {
          value: parseInt(data.after),
        };

        $.ajax({
          url: `${vm.apiInverterUrl}/${deviceId}/${parseInt(data.after)}`,
          // dataType: 'json',
          type: "post",
          headers: {
            "X-Pin": pin,
          },
          // contentType: 'application/json',
          data: JSON.stringify(newData),
          // processData: false,
          success: function (response) {
            alert("Set Inverter Speed Success!");
            // refreshTable();
          },
          error: function () {
            alert("Set Inverter Speed Failed!");
            refreshTable();
          },
          complete: function () {
            vm.isPauseAutoLoad = false;
          }
        });
      }

      function setECO(device, status, automationTarget, pin, highLimit, lowLimit) {
        let ecoStatus = "";
        if (status == 1) {
          ecoStatus = true;
        } else {
          ecoStatus = false;
        }

        if (!automationTarget) {
          alert(`Warning - Please fill in Ambient SV °C`);
          return;
        }
        if (!highLimit || !lowLimit) {
          alert(`Warning - Please fill in max & min value`);
          return;
        }
        if (isNaN(automationTarget) || isNaN(highLimit) || isNaN(lowLimit)) {
          alert(`Warning - Please fill in numbers only`);
          return;
        }

        console.log("Controlling automation", device.deviceId);

        $.ajax({
          type: "get",
          headers: {
            "X-Pin": pin,
          },
          url: `${vm.apiUrl}/updateAutomationStatus/${device.deviceId}/${status}/${automationTarget}/${lowLimit}/${highLimit}`,
          success: function (response) {
            if (response.data.message) {
              // not allow automation
              alert(response.data.message);
              refreshTable();
            } else {
              alert(
                  `Automation status for ${device.deviceId} has been set to ${
                      ecoStatus ? "'ON'" : "'OFF'. Settings applied and will take 1 to 30 mins to be updated on dashboard."
                  }`
              );
              let initialData = vm.automationBefore[device.deviceId];
              if (initialData) {
                // status
                if ('status' in initialData && (initialData.status !== ecoStatus)) {
                  const statusBefore = initialData.status ? "ON" : "OFF";
                  const statusAfter = ecoStatus ? "ON" : "OFF";
                  saveLog(statusBefore, statusAfter, "automation_status", device.deviceId)
                }
                // target
                if (initialData.hasOwnProperty("target") && (initialData.target != automationTarget)) {
                  saveLog(initialData.target, automationTarget, "automation_target", device.deviceId)
                }
                delete vm.automationBefore[device.deviceId];
              }
              // automation range
              const initialRange = vm.automationRange[device.deviceId];
              if ((+initialRange.max !== +highLimit)) {
                saveLog(initialRange.max, +highLimit, "automation_range", device.deviceId)
              } else if ((+initialRange.min !== +lowLimit)) {
                saveLog(initialRange.min, +lowLimit, "automation_range", device.deviceId)
              }
              refreshTable();
            }
          },
          error: function (e) {
            alert(`Automation failed to turn ${ecoStatus ? "ON" : "OFF"}`);
            console.log(e);
            refreshTable();
          },
          complete: function() {
            vm.isPauseAutoLoad = false;
          }
        });
      }

      function renderSensor(data) {
        return data.replace(/,/g, ', ');
      };

      function loadTable() {
        var table = $("#control").DataTable({
          ajax: {
            url: `${vm.mainUrl}/data/controlPageSimulation`,
            dataSrc: "data"
          },
          fixedHeader: true,
          responsive: true,
          searching: false,
          order: [[1, 'desc']],
          ordering: false,
          columns: [
            {
              data: "phase",
              class: "center abnormal-col",
              render: function (data, _, row) {
                let isAbnormal = false;
                if (row.abnormality && row.abnormality.status) {
                  isAbnormal = true;
                }
                return `
                  ${isAbnormal ? `
                    <div class="info-wrapper" data-toggle="tooltip" title="${row.abnormality.description}">
                      <div class="abnormal-info"><i class='fa fa-info'></i></div>
                    </div>` : ''}
                  <div>${data == null ? '-' : data}</div>
                `;
              }
            },
            {
              data: "deviceId",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data;
              }
            },
            {
              data: "txSensor",
              class: "center tx-col",
              render: function (data) {
                // return data == null ? '-' : data;
                return `
                  <div id="tx-sensor" style="margin: auto 5px; min-width: 100px">${data == null ? '-' : renderSensor(data)}</div>
                    <!-- render at createdRow -->
                  <div class="tx-chip">-</div>
                  <button class="tx-edit"><i class='fa fa-pencil'></i></button>`;
              }
            },
            {
              data: "isAuto",
              class: "center",
              render: function (data) {
                return `
                <span class="machine-mode" style="background-color: ${data === 1 ? 'green' : data === 0 ? 'red' : 'grey'}">
                    ${data === 1 ? 'ON' : data === 0 ? 'OFF' : '-'}
                </span>
                `;
              }
            },
            {
              data: "temperature",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data.toString().substring(0, 5);
              },
            },
            {
              data: "humidity",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data.toString().substring(0, 5);
              }
            },
            {
              data: "supplyTemp",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data.toString().substring(0, 4);
              }
            },
            {
              data: "returnTemp",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data.toString().substring(0, 4);
              }
            },
            {
              data: "isManualMv",
              class: "center",
              render: function (data) {
                return `
                <span class="machine-mode" style="background-color: ${data === 1 ? 'darkorange' : data === 0 ? 'blue' : 'grey'}">
                    ${data === 1 ? 'MANUAL (MV)' : data === 0 ? 'AUTO (SV)' : '-'}
                </span>
                `;
              }
            },
            {
              data: "setPoint",
              class: "center",
              width: "6%",
              render: function (data) {
                return `
            <div class="btn-group">
              <button id="addTemp" class="increase-temp" style="background-color: blue">+</button>
              <button class="remain-temp" style="background-color: #00bd39">${data != null ? data.toString().substring(0, 4) : '-'}</button>
              <button class="decrease-temp" style="background-color: red">-</button>
            </div>`;
              },
            },
            {
              data: "valveControl",
              class: "center",
              width: "6%",
              render: function (data) {
                return `
            <div class="btn-group">
              <button id="addMv" class="increase-mv" style="background-color: blue">+</button>
              <button class="remain-mv" style="background-color: #00bd39">${data != null ? data.toString().substring(0, 4) : '-'}</button>
              <button class="decrease-mv" style="background-color: red">-</button>
            </div>`;
              },
            },
            {
              data: "valveStatus",
              class: "center",
              render: function (data) {
                return data == null ? '-' : data.toString().substring(0, 4);
              }
            },
            {
              data: "automation",
              class: "center",
              width: "6%",
              render: function (data, type, row) {
                let onColor = "";
                let offColor = "";
                let ecoColor = "";
                const boolCheck = parseInt(data);
                if (boolCheck) {
                  onColor = "blue";
                  offColor = "grey";
                  ecoColor = "#00bd39";
                } else {
                  onColor = "grey";
                  offColor = "red";
                  ecoColor = "grey";
                }
                return `<div class="btn-group"><button class="onECO" style="background-color: ${onColor}">ON</button><div class="display-box" style="background-color: ${ecoColor}">AUTO</div><button class="offECO" style="background-color: ${offColor}">OFF</button></div>`;
              },
            },
            {
              data: "fixedvalue",
              class: "center",
              render: function (data, type, row) {
                return `<input type="number" class="fixedvalue-input" value=${data} step="0.1">`;
              },
            },
            {
              data: null,
              class: "center",
              render: function (data, type, row) {
                return `
                    <label style="font-size: 14px" for="svHighLimit">max: </label>
                    <input id="svHighLimit" type="number" value=${row.svHighLimit ?? 0} step="0.1" class="svLimit"><br>
                    <label style="font-size: 14px" class="mt-2" for="svLowLimit">min: </label>
                    <input id="svLowLimit" type="number" value=${row.svLowLimit ?? 0} step="0.1" class="svLimit">
                `;
              },
            },
            {
              data: "setFreq",
              class: "center",
              width: "6%",
              render: function (data, type, row) {
                const enable = inverterControl[row.deviceId];
                if (enable) {
                  return `
                    <div class="btn-group">
                      <button class="increase-inverter-speed">+</button>
                      <button class="remain-inverter-speed">${data == null ? '0' : data}</button>
                      <button class="decrease-inverter-speed">-</button>
                    </div>`
                } else {
                  return `
                  <span class="inverter-disabled">NOT AVAILABLE</span>`
                }
                ;
              },
            },
          ],
          drawCallback: function (settings) {
            // to prevent onclick event from being fired multiple times
            $(".fixedvalue-input").off("input");
            $("#refresh-btn").off("click");
            $(".remain-temp").off("click");
            $(".increase-temp").off("click");
            $(".decrease-temp").off("click");
            $(".remain-mv").off("click");
            $(".increase-mv").off("click");
            $(".decrease-mv").off("click");
            $(".remain-inverter-speed").off("click");
            $(".increase-inverter-speed").off("click");
            $(".decrease-inverter-speed").off("click");
            $(".onECO").off("click");
            $(".offECO").off("click");

            $(".fixedvalue-input").on("input", function () {
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              const setPointChanged = $(this).val() != rowData.fixedvalue;
              row.find(".onECO").html(`ON${setPointChanged ? "*" : ""}`);
              rowData.setPointChanged = setPointChanged;

              if (setPointChanged && !vm.automationBefore[rowData.deviceId]) {
                vm.automationBefore[rowData.deviceId] = {
                  target: rowData.fixedvalue
                };
                console.log(vm.automationBefore)
              }
              ;
            });
            $("#refresh-btn").on("click", function (e) {
              refreshTable();
            });
            $(".remain-temp").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              const pin = await vm.getPin();
              if (pin) {
                const row = $(this).closest("tr");
                const rowData = table.row(row).data();
                const data = {
                  before: parseFloat(row.find('.remain-temp').html()),
                  after: parseFloat(row.find('.remain-temp').html()),
                }
                console.log("Remain temp", rowData.deviceId);
                setTemperature(rowData.deviceId, data, pin);
                // refreshTable();
              } else {
                refreshTable();
                vm.isPauseAutoLoad = false;
              }
            });

            $(".tx-edit").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.sensorList = [];
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              let sensorArr = rowData.txSensor.split(',');
              let selectedAHU = rowData.deviceId;
              await vm.updateSensorDetails();
              // all sensors in the selected AHU should have 1 same operation
              vm.selectedOperation = vm.sensorDetails[sensorArr[0]].operation;
              sensorArr.forEach((sensor) => {
                let x = {
                  name: sensor,
                  isSelected: vm.sensorDetails[sensor][selectedAHU].isSelected,
                  ahu: selectedAHU,
                }
                vm.sensorList.push(x);
              })
              // show modal
              vm.isEditVisible = true;
              vm.isEditSensor = true;
            });

            $(".increase-temp").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.increaseTempChange += 0.1;
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              if (vm.initValue === 0) {
                vm.initValue = parseFloat(row.find('.remain-temp').html());
              }
              vm.tempBefore = parseFloat(row.find('.remain-temp').html());

              if ((+(vm.tempBefore)).toFixed(1) === (rowData.svHighLimit).toFixed(1)) {
                alert('Maximum SV is ' + rowData.svHighLimit);
                vm.isPauseAutoLoad = false;
              } else {
                let newValue = vm.initValue + vm.increaseTempChange;
                if (newValue > rowData.svHighLimit) newValue = rowData.svHighLimit;
                let toDisplay = parseFloat(newValue).toFixed(1);
                row.find('.remain-temp').html(toDisplay);
                // const pin = await getPin("Are you sure you want to increase Set Point temperature?");
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("increase temp", rowData.deviceId);
                    let data = {
                      before: vm.initValue,
                      after: parseFloat(newValue),
                    }
                    setTemperature(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-temp').html(vm.initValue);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.increaseTempChange = 0;
                  vm.tempBefore = 0;
                  vm.initValue = 0;
                }, vm.changeDelay);
              }
              ;
            });
            $(".decrease-temp").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.decreaseTempChange += 0.1;
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();

              if (vm.initValue === 0) {
                vm.initValue = parseFloat(row.find('.remain-temp').html());
              }
              vm.tempBefore = parseFloat(row.find('.remain-temp').html());
              if ((+(vm.tempBefore)).toFixed(1) === (rowData.svLowLimit).toFixed(1)) {
                alert('Minimum SV is ' + rowData.svLowLimit);
                vm.isPauseAutoLoad = false;
              } else {
                let newValue = vm.initValue - vm.decreaseTempChange;
                if (newValue < rowData.svLowLimit) newValue = rowData.svLowLimit;
                let toDisplay = parseFloat(newValue).toFixed(1);
                row.find('.remain-temp').html(toDisplay);
                // const pin = await getPin("Are you sure you want to increase Set Point temperature?");
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("decrease temp", rowData.deviceId);
                    let data = {
                      before: vm.initValue,
                      after: parseFloat(newValue),
                    }
                    setTemperature(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-temp').html(vm.initValue);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.decreaseTempChange = 0;
                  vm.tempBefore = 0;
                  vm.initValue = 0;
                }, vm.changeDelay);
              }
              ;
            });
            $(".remain-mv").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              let pin = await vm.getPin();
              if (pin) {
                let row = $(this).closest("tr");
                let rowData = table.row(row).data();
                let data = {
                  before: parseFloat(row.find('.remain-mv').html()),
                  after: parseFloat(row.find('.remain-mv').html()),
                }
                console.log("Remain mv", rowData.deviceId);
                setMv(rowData.deviceId, data, pin);
                // refreshTable();
              } else {
                refreshTable();
                vm.isPauseAutoLoad = false;
              }
            });
            $(".increase-mv").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.increaseMvChange += 1;
              let row = $(this).closest("tr")
              let rowData = table.row(row).data();
              if (vm.initValue === 0) {
                vm.initValue = parseFloat(row.find('.remain-mv').html());
              }
              vm.mvBefore = parseFloat(row.find('.remain-mv').html());
              const limit = vm.limitResult.find(x => x.deviceId === rowData.deviceId);
              if ((+(vm.mvBefore)).toFixed(1) === (+(limit.mvHighLimit)).toFixed(1)) {
                alert('Maximum MV is ' + limit.mvHighLimit);
                vm.isPauseAutoLoad = false;
                refreshTable();
              } else {
                let newValue = vm.initValue + vm.increaseMvChange;
                if (newValue > limit.mvHighLimit) newValue = limit.mvHighLimit;
                let toDisplay = parseFloat(newValue).toFixed(1);
                row.find('.remain-mv').html(toDisplay);
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("increase mv", rowData.deviceId);
                    let data = {
                      before: vm.initValue,
                      after: parseFloat(newValue),
                    }
                    setMv(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-mv').html(vm.initValue);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.increaseMvChange = 0;
                  vm.mvBefore = 0;
                  vm.initValue = 0;
                }, vm.changeDelay);
              }
              ;
            });
            $(".decrease-mv").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.decreaseMvChange += 1;
              let row = $(this).closest("tr")
              let rowData = table.row(row).data();
              if (vm.initValue === 0) {
                vm.initValue = parseFloat(row.find('.remain-mv').html());
              }
              vm.mvBefore = parseFloat(row.find('.remain-mv').html());
              const limit = vm.limitResult.find(x => x.deviceId === rowData.deviceId);
              if ((+(vm.mvBefore)).toFixed(1) === (+(limit.mvLowLimit)).toFixed(1)) {
                alert('Minimum MV is ' + limit.mvLowLimit);
                vm.isPauseAutoLoad = false;
                refreshTable();
              } else {
                let newValue = vm.initValue - vm.decreaseMvChange;
                if (newValue < limit.mvLowLimit) newValue = limit.mvLowLimit;
                let toDisplay = parseFloat(newValue).toFixed(1);
                row.find('.remain-mv').html(toDisplay);
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("decrease mv", rowData.deviceId);
                    let data = {
                      before: vm.initValue,
                      after: parseFloat(newValue),
                    }
                    setMv(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-mv').html(vm.initValue);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.decreaseMvChange = 0;
                  vm.mvBefore = 0;
                  vm.initValue = 0;
                }, vm.changeDelay);
              }
              ;
            });
            $(".remain-inverter-speed").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              let pin = await vm.getPin();
              if (pin) {
                let row = $(this).closest("tr");
                let rowData = table.row(row).data();
                let data = {
                  before: parseFloat(row.find('.remain-inverter-speed').html()),
                  after: parseFloat(row.find('.remain-inverter-speed').html()),
                }
                console.log("Remain inverter speed", rowData.deviceId);
                setInverterSpeed(rowData.deviceId, data, pin);
                // refreshTable();
              } else {
                refreshTable();
                vm.isPauseAutoLoad = false;
              }
            });
            $(".increase-inverter-speed").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              vm.increaseInverterChange += 1.0;
              let row = $(this).closest("tr")
              let rowData = table.row(row).data();
              const limit = vm.limitResult.find(x => x.deviceId === rowData.deviceId);
              if (vm.inverterBefore === 0) {
                vm.inverterBefore = parseFloat(row.find('.remain-inverter-speed').html());
              }
              let newValue = vm.inverterBefore + vm.increaseInverterChange;
              if (newValue <= limit.inverterHighLimit) {
                row.find('.remain-inverter-speed').html(newValue);
                // const pin = await getPin("Are you sure you want to increase Set Point temperature?");
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("inc speed", rowData.deviceId);
                    let data = {
                      before: vm.inverterBefore,
                      after: parseFloat(newValue),
                    }
                    setInverterSpeed(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-inverter-speed').html(vm.inverterBefore);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.increaseInverterChange = 0;
                  vm.inverterBefore = 0;
                }, vm.changeDelay);
              } else {
                alert('Maximum inverter speed is ' + limit.inverterHighLimit);
                vm.isPauseAutoLoad = false;
                refreshTable();
              }
              ;
            });
            $(".decrease-inverter-speed").on("click", async function (e) {
              vm.isPauseAutoLoad = false;
              vm.decreaseInverterChange += 1.0;
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              const limit = vm.limitResult.find(x => x.deviceId === rowData.deviceId);
              if (vm.inverterBefore === 0) {
                vm.inverterBefore = parseFloat(row.find('.remain-inverter-speed').html());
              }
              let newValue = vm.inverterBefore - vm.decreaseInverterChange;
              if (newValue >= limit.inverterLowLimit) {
                row.find('.remain-inverter-speed').html(newValue);
                // const pin = await getPin("Are you sure you want to increase Set Point temperature?");
                // const pin = await getPin("Are you sure you want to increase Set Point temperature?");
                clearTimeout(vm.isDelay);
                vm.isDelay = setTimeout(async () => {
                  let pin = await vm.getPin();
                  if (pin) {
                    console.log("dec speed", rowData.deviceId);
                    let data = {
                      before: vm.inverterBefore,
                      after: parseFloat(newValue),
                    }
                    setInverterSpeed(rowData.deviceId, data, pin);
                    // refreshTable();
                  } else {
                    row.find('.remain-inverter-speed').html(vm.inverterBefore);
                    vm.isPauseAutoLoad = false;
                    refreshTable();
                  }
                  ;
                  vm.decreaseInverterChange = 0;
                  vm.inverterBefore = 0;
                }, vm.changeDelay);
              } else {
                alert('Minimum inverter speed is ' + limit.inverterLowLimit);
                vm.isPauseAutoLoad = false;
                refreshTable();
              }
              ;
            });
            $(".onECO").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              // alert('Automation is disabled for now. Control using MV')
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              let fixedValue = row.find(".fixedvalue-input").val();
              let highLimit = +row.find("#svHighLimit").val();
              let lowLimit = +row.find("#svLowLimit").val();

              if (!highLimit || !lowLimit) {
                alert('Please input automation SV control range first');
                return;
              }
              const pin = await vm.getPin();
              if (pin) {
                let status = row.find(".onECO").css("background-color");
                // rgb(128, 128, 128) = grey (off)
                status = (status !== "rgb(128, 128, 128)");
                let initialData = vm.automationBefore[rowData.deviceId]
                if (initialData) vm.automationBefore[rowData.deviceId].status = status;
                else vm.automationBefore[rowData.deviceId] = {
                  status: status
                };
                setECO(rowData, 1, fixedValue, pin, highLimit, lowLimit);
                // refreshTable();
              } else {
                refreshTable();
                vm.isPauseAutoLoad = false;
              }
              ;
            });
            $(".offECO").on("click", async function (e) {
              vm.isPauseAutoLoad = true;
              // alert('Automation is disabled for now. Control using MV')
              let row = $(this).closest("tr");
              let rowData = table.row(row).data();
              let fixedValue = row.find(".fixedvalue-input").val();
              let highLimit = +row.find("#svHighLimit").val();
              let lowLimit = +row.find("#svLowLimit").val();

              if (!highLimit || !lowLimit) {
                alert(`Don't let automation SV control range empty!`);
                return;
              }

              const pin = await vm.getPin();
              if (pin) {
                let status = row.find(".onECO").css("background-color");
                // rgb(128, 128, 128) = grey (off)
                if (status === "rgb(128, 128, 128)") status = false;
                else status = true;
                let initialData = vm.automationBefore[rowData.deviceId]
                if (initialData) vm.automationBefore[rowData.deviceId].status = status;
                else vm.automationBefore[rowData.deviceId] = {
                  status: status
                };
                setECO(rowData, 0, fixedValue, pin, highLimit, lowLimit);
                // refreshTable();
              } else {
                refreshTable();
                vm.isPauseAutoLoad = false;
              }
              ;
            });
            // testing
            $(".display-box").on("click", function (e) {
              let rowData = table.row($(this).closest("tr")).data();
              const automationDevices = $(this)
                  .closest("tr")
                  .find("#sensors-multiselect input:checked")
                  .map(function () {
                    return $(this).attr("value");
                  })
                  .toArray()
                  .join(",");
              const fixedValue = $(this)
                  .closest("tr")
                  .find(".fixedvalue-input")
                  .val();
            });
          },
          createdRow: function (row, data) {
            if (data.abnormality && data.abnormality.status) {
              $(row).css("background-color", "#b7490745");
            }

            if (data.deviceId) {
              // variable to store value before change. This is for audit purpose
              vm.automationRange[data.deviceId] = {
                min: data.svLowLimit,
                max: data.svHighLimit
              }
            }

            if (data.txSensor) {
              let sensorList = data.txSensor.split(',');
              // assign color for sensor status
              let toEdit = $(row).find('#tx-sensor');
              let html = '';
              for (let i = 0; i < sensorList.length; i++) {
                let color = 'darkgrey'
                if (vm.sensorDetails[sensorList[i]][data.deviceId].isSelected) color = 'greenyellow';
                html += `${i !== 0 ? ', ' : ''}<span style="color: ${color}"; font-weight: "bold">${sensorList[i]}</span>`
              }
              toEdit.html(html);

              // render chip
              toEdit = $(row).find('.tx-chip');
              const operation = vm.sensorDetails[sensorList[0]].operation;
              toEdit.text(operation);
            }
          }
        });

        table.on("page.dt", function () {
          const data = Object.values(table.rows().data());
          const newPage = table.page.info().page;

          const changes = [];
          table.rows().every(function () {
            if (this.data().setPointChanged) {
              changes.push(this.data().deviceId);
            }
          });

          if (page != newPage && changes.length > 0) {
            if (
                confirm(
                    `Set points of the following AHUs not yet saved. Discard?\n\n${changes.join(
                        "\n"
                    )}`
                )
            ) {
              // redraw table
              table.rows().every(function () {
                if (this.data().setPointChanged) {
                  delete this.data().setPointChanged;
                  this.invalidate();
                }
              });
              table.draw();
              table.page(newPage);
              page = newPage;
            } else {
              // go back to previous page
              table.page(page);
            }
          } else {
            page = newPage;
          }
        });
      }

      var checkExist = setTimeout(function () {
        if ($("#control").length) {
          clearTimeout(checkExist);
          loadTable();
          vm.isAutoLoad = true;
        }
      }, 100); // check every 100ms

      if (autoLoadTable) clearInterval(autoLoadTable);
      var autoLoadTable = setInterval(function () {
        if (vm.isAutoLoad && !vm.isPauseAutoLoad) {
          let afterPauseDelay = setTimeout(() => {
            refreshTable();
            clearTimeout(afterPauseDelay);
          }, 3000)
        }
      }, 30000)
    });
  },
};
</script>

<style>
body {
  background-color: #000000;
}

#controlPage {
  margin: 0px;
  overflow-x: auto;
}

#control_filter {
  margin-bottom: 20px;
}

/* Overall table style */
#controlPage,
#control_length,
#control_filter,
#control_info,
#control_previous,
#control_next {
  color: rgb(236, 236, 236) !important;
  background-color: black;
}

select {
  color: white !important;
}

#control_paginate a.paginate_button {
  background: #242629;
  border: none !important;
  color: white !important;
}

thead {
  background: #212529 !important;
}

th {
  border: 1px solid rgba(70, 70, 70, 0.7);
}

#control_paginate a.paginate_button:hover {
  background: #ff5e00;
  border: none !important;
  color: white !important;
}

#control_paginate #control_next,
#control_paginate #control_previous {
  margin-top: 8px;
  background: transparent;
  border: none !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: white !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button.current {
  background: #212529 !important;
  border: none !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_length select {
  background-color: black;
}

.dataTables_length, .dataTables_info {
  padding: 8px;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button.current:hover {
  background: #444f59 !important;
  color: white !important;
}

#controlPage #control_wrapper #control tbody tr td {
  background-color: transparent;
  border: 1px solid rgba(70, 70, 70, 0.7);
}

#control_wrapper tr {
  background-color: transparent;
  color: white;
  border: none;
}

input {
  color: white;
}

/* align center table content */
#controlPage .center {
  text-align: center;
}

#controlPage .tx-col,
#controlPage .abnormal-col {
  position: relative;
}

#controlPage .btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(107, 107, 107, 0.3);
  border-radius: 20%;
  margin: 10px;
}

#controlPage .btn:active {
  box-shadow: inset 0 0 10px #a8a8a8;
}

.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn-group button {
  border: 1px solid rgba(87, 87, 87, 0.3);
  color: white;
  padding: 0px 5px 0px 5px;
  width: 70%;
  text-align: center;
  display: inline-block;
  background-color: transparent;
}

.btn-group .display-box {
  border: 1px solid rgba(87, 87, 87, 0.3);
  background-color: #00bd39;
  width: 70%;
  padding: 0px 5px 0px 5px;
  display: inline-block;
}

.btn-group button:active {
  box-shadow: inset 0 0 10px #494949c0;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #000000;
}

.checkbox-box {
  width: 40% !important;
}

.checkbox-label {
  width: 60% !important;
}

.fixedvalue-input {
  width: 5vw;
  height: 30px;
  margin: 0;
  padding: 2px 8px;
  background: #242629;
  border: 1px solid #ccccdc26;
}

.svLimit {
  width: 5vw;
  margin: 0 0 5px;
  padding: 0 5px;
  background: #242629;
  border: 1px solid #ccccdc26;
}

.decrease-inverter-speed {
  background-color: red !important;
}

.remain-inverter-speed {
  background-color: #00bd39 !important;
}

.increase-inverter-speed {
  background-color: blue !important;
}

.decrease-inverter-speed:disabled {
  background-color: grey !important;
  pointer-events: none;
}

.remain-inverter-speed:disabled {
  background-color: grey !important;
  pointer-events: none;
}

.increase-inverter-speed:disabled {
  background-color: grey !important;
  pointer-events: none;
}

.machine-mode {
  color: white;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.inverter-disabled {
  color: white;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  background-color: grey;
}

.dtfh-floatingparenthead {
  top: 4vw !important;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

table.dataTable thead th {
  text-align: center;
  color: white !important;
}

.tx-box {
  display: flex;
  justify-content: flex-end;
}

.tx-edit {
  position: absolute;
  background-color: transparent;
  border: none;
  color: white;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  bottom: 4px;
  right: 4px;
}

.tx-chip {
  background-color: rebeccapurple;
  border: none;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  max-width: 35px;
  padding: 1px;
  margin: 5px auto 0px;
}

.tx-edit:hover {
  background-color: #ba5c20;
}

.tx-edit:disabled {
  /* background-color: #ffbc39; */
  background-color: #808080;
  cursor: not-allowed;
}

.info-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.abnormal-info {
  display: block;
  position: relative;
  height: 30px;
  width: 0px;
  cursor: pointer;
}
.abnormal-info:before {
  content: '';
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid red;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.abnormal-info i {
  display: block;
  text-align: center;
  height: 25px;
  width: 15px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  font-size: 14px;
}

</style>
